<template>
  <div>
    <fieldset>
      <legend>General</legend>
      <div class="row">
        <div class="col-md-2">
          <FormGroupInput
              v-model="value.symbol"
              fieldname="symbol"
              :cfg="modelConfig.fields.symbol"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              v-model="value.company_name"
              fieldname="company_name"
              :cfg="modelConfig.fields.company_name"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-2">
          <FormGroupSelect
              :cfg="modelConfig.fields.adr_country_id"
              v-model="value.adr_country_id"
              fieldname="adr_country_id"
              :label="modelConfig.fields.adr_country_id.label"
              :titleProperty="modelConfig.fields.adr_country_id.resolve.titleProperty"
              :optionsDataService="modelConfig.fields.adr_country_id.resolve.optionsDataService"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-2">
          <FormGroupInput
              v-model="value.color1"
              fieldname="color1"
              :cfg="modelConfig.fields.color1"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-2">
          <FormGroupInput
              v-model="value.color2"
              fieldname="color2"
              :cfg="modelConfig.fields.color2"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
      </div>
    </fieldset>
    <div class="row">
      <div class="col-md-4">
        <FormGroupInput
            v-model="value.adr_street"
            fieldname="adr_street"
            :cfg="modelConfig.fields.adr_street"
            :validation="validation"
            @keyup.ctrl.enter="$emit('formSubmitRequest')"
        />
      </div>
      <div class="col-md-4">
        <FormGroupInput
            v-model="value.adr_zip"
            fieldname="adr_zip"
            :cfg="modelConfig.fields.adr_zip"
            :validation="validation"
            @keyup.ctrl.enter="$emit('formSubmitRequest')"
        />
      </div>
      <div class="col-md-4">
        <FormGroupInput
            v-model="value.adr_city"
            fieldname="adr_city"
            :cfg="modelConfig.fields.adr_city"
            :validation="validation"
            @keyup.ctrl.enter="$emit('formSubmitRequest')"
        />
      </div>
    </div>
    <fieldset>
      <legend>Invoice Information</legend>
      <div class="row">
        <div class="col-md-12">
          <FormGroupInput
              v-model="value.billing_email_address"
              fieldname="billing_email_address"
              :cfg="modelConfig.fields.billing_email_address"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <FormGroupRichtext
              v-model="value.invoice_text_additional"
              fieldname="invoice_text_additional"
              :label="modelConfig.fields.invoice_text_additional.label"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupTextarea
              v-model="value.invoice_email_text"
              fieldname="invoice_email_text"
              :label="modelConfig.fields.invoice_email_text.label"
              :validation="validation"
              :cfg="modelConfig.fields.invoice_email_text"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupRichtext
              v-model="value.invoice_email_html"
              fieldname="invoice_email_html"
              :label="modelConfig.fields.invoice_email_html.label"
              :validation="validation"
              @keyup.ctrl.enter="$emit('formSubmitRequest')"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>

import FormGroupInput from '@/components/Form/FormGroup/Input.vue';
import FormGroupSelect from '@/components/Form/FormGroup/Select.vue';
import FormGroupTextarea from "@/components/Form/FormGroup/Textarea";
import FormGroupRichtext from "@/components/Form/FormGroup/Richtext";

export default {
  props: {
    modelConfig: null,
    dataService: null,
    modelValue: null,
    validation: null,
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  name: "CustomerFormFields",
  components: {
    FormGroupRichtext,
    FormGroupTextarea,
    FormGroupInput,
    FormGroupSelect,
  },
  methods: {
  }
}
</script>
