<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import CustomerDataService from "@/services/CustomerDataService";
import FormFields from "@/components/CustomerForm/Fields";

export default {
  name: 'CustomerForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'customers' },
    vueSlug: { default: 'customer' }
  },
  components: {
    FormFields,
  },
  data() {
    return {
      dataService: CustomerDataService,
      modelConfig: CustomerDataService.getEditorConfig(),
      currentEntity: CustomerDataService.getEntityDefinition(),
    }
  },
  setup() {
    return ModelForm.setup();
  },
  validations() {
    return {
      currentEntity: this.dataService.getValidationRules()
    }
  }
}
</script>
